import { useTranslation } from "react-i18next"
import { ImageUploaderUI } from "./ImageUploader.ui"
import { FC, useState } from "react"
import { Control, Controller } from "react-hook-form"
import { ImagePreview } from "./Image.preview"
import classNames from "classnames"
import { Button } from "@mui/material"
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

type ImageUploaderMultipleProps = {
  label?: string
  onChange?: (files: File[]) => void
}
export const ImageUploaderMultiple: FC<ImageUploaderMultipleProps> = ({ label, onChange }) => {
  const { t } = useTranslation("common")
  const [compressedFiles, setCompressedFiles] = useState<File[]>([])
  const [localPreview, setLocalPreviews] = useState<string[]>([])

  const deleteImage = (i: number) => {
    const tempPreview = [...localPreview]
    tempPreview.splice(i, 1)
    setLocalPreviews(tempPreview)
    const tempCompressed = [...compressedFiles]
    tempCompressed.splice(i, 1)
    setCompressedFiles(tempCompressed)
    onChange && onChange(tempCompressed)
  }

  return <ImageUploaderUI
    onDrop={(files: File[], preview: string[]) => {
      setCompressedFiles([...compressedFiles, ...files])
      setLocalPreviews([...preview, ...localPreview])
      onChange && onChange([...compressedFiles, ...files])
    }}
    multiple
    label={label ?? t('form.imageInput.labelMultiple')}
  >
    <Button
      component="span"
      variant="contained">
      {t('form.imageInput.addBtn')}
      <AddPhotoAlternateIcon />
    </Button>

    <div className={classNames("flex flex-wrap w-full p-2 gap-3 justify-center",
      {
        "hidden": !localPreview || localPreview.length === 0
      }
    )}>
      {
        localPreview.map((img, i) => img !== "" && <div key={"img-" + i} className='relative'>
          <ImagePreview
            size="medium"
            image={img}
            onClick={(e) => {
              // if (hasCrop) {
              //   e.stopPropagation()
              //   e.preventDefault()
              //   setImgForCrop(i)
              // }
            }}
            allowDelete={true}
            onDelete={() => {
              deleteImage(i)
            }}
          />
        </div>)
      }
    </div>
  </ImageUploaderUI>
}

type ImageUploaderMultipleControllerProps = {
  name: string
  control: Control
} & ImageUploaderMultipleProps
export const ImageUploaderMultipleController: FC<ImageUploaderMultipleControllerProps> = (
  { name, control, onChange, ...props }
) => {
  return <Controller
    control={control}
    name={name}
    render={({ field }) => <ImageUploaderMultiple
      onChange={(files) => {
        field.onChange(files)
        onChange && onChange(files)
      }}
      {...props} />}
  />

}