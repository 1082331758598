import { Avatar, Box, Button, Chip, Modal, Tooltip, Typography } from "@mui/material";
import { ITicketUser } from "@wattsonelements/front-fdk/dist/models/User.models";
import dayjs from "dayjs";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageLoader } from "../../elements/ImageLoader.element";
import { ImageGallery } from "../Gallery";
interface ChatMessageProps {
  isUserMesage?: boolean;
  message?: string;
  avatar?: string;
  files?: string[];
  date?: string;
  isSystem?: boolean;
  user?: ITicketUser
  dateFormat?: (date: string) => string;
  [key: string]: any;
}

export const ChatMessage: FC<ChatMessageProps> = (props) => {
  const { t } = useTranslation("common");

  const {
    isUserMesage = false,
    isSystem = false,
    message,
    avatar,
    files,
    date,
    user,
    dateFormat = (date: string) =>
      dayjs(date).format(t("dates.abbreviation_month_date_hour")),
  } = props

  if (isSystem) {
    return <SystemMessage dateFormat={dateFormat} message={message} date={date} files={files} />
  } else {
    return (
      <UserMessage user={user} avatar={avatar} isUserMesage={isUserMesage} dateFormat={dateFormat} message={message} date={date} files={files} />
    );
  }
};

type SystemMessageProps = {
  date?: string,
  dateFormat: (date: string) => string;
  message?: string,
  files?: string[]
}
const SystemMessage: FC<SystemMessageProps> = ({ date, files, dateFormat, message }) => {

  return <Box className="rounded-full text-[#5c6066]">
    {date && (
      <Typography
        className="px-2"
        sx={{
          fontSize: "10px",
        }}
        variant="subtitle2"
        align="center"
      >
        {dateFormat(date)}
      </Typography>
    )}
    <Typography sx={{
      fontSize: "12px",
      lineHeight: "0.8em",
      marginBottom: "10px"
    }} className="break-all " align="center">
      {message}
    </Typography>
    {
      files && <ImageGallery className="justify-center items-center" images={files} />
    }
  </Box>
}

type UserMessageProps = {
  isUserMesage?: boolean;
  message?: string;
  avatar?: string;
  files?: string[];
  date?: string;
  isSystem?: boolean;
  user?: ITicketUser
  dateFormat: (date: string) => string;
}
const UserMessage: FC<UserMessageProps> = (props) => {
  const {
    isUserMesage = false,
    message,
    avatar,
    files,
    date,
    user,
    dateFormat
  } = props
  return <Box
    className={
      "flex items-end " + (isUserMesage ? "flex-row-reverse" : "flex-row")
    }
  >
    {user ?
      <Tooltip
        key={`user-chattooltip`}
        placement="top"
        title={user.first_name + " " + user.last_name}
      >
        <Avatar src={user.picture} />
      </Tooltip>
      :
      <Avatar src={avatar} />
    }

    <Box className={isUserMesage ? "mr-2" : "ml-2"}>
      {date && (
        <Typography
          className="px-2"
          sx={{
            fontSize: "10px",
          }}
          variant="subtitle2"
          align={isUserMesage ? "right" : "left"}
        >
          {user && `${user.first_name} ${user.last_name} - `}{dateFormat(date)}
        </Typography>
      )}
      <Box
        className={
          "rounded-2xl p-4 " +
          (isUserMesage ? "bg-fcosecondary" : "bg-fcogrey")
        }
      >
        <Typography className="whitespace-pre-wrap" sx={{ color: isUserMesage ? "white" : "inherit" }}>
          {message}
        </Typography>
        {files && <ImageGallery className="justify-center items-center" images={files} />}
      </Box>
    </Box>
  </Box>
}

type ImageMessageProps = {
  files: string[]
}
export const ImageMessage: FC<ImageMessageProps> = ({ files }) => {
  const [modalOpened, setModalOpened] = useState(-1);

  return <Box className="flex w-full justify-center">
    {files?.map((file, i) => (
      [
        <Modal
          key={`modal-img-${i}`}
          className="overflow-y-auto flex items-center justify-center p-5 md:p-24"
          open={modalOpened === i}
          onClose={() => setModalOpened(-1)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {/* <Box className="flex items-center justify-center block overflow-hidden relative rounded-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-5/6 w-3/4 shadow-md"> */}
          {/* <img key={`img-${i}`} src={file} /> */}
          <ImageLoader alt={`chat image-${i}`} key={`img-${i}`} src={file} />
          {/* </Box> */}
        </Modal>,
        <Button key={`button-img-${i}`} onClick={() => setModalOpened(i)}>
          <Box className="p-1">
            {/* <img className="rounded-lg" src={file} /> */}
            <ImageLoader className="rounded-lg object-cover w-full h-full" alt={`chat image-${i}`} key={`img-${i}`} src={file} />
          </Box>
        </Button>
      ]
    ))}
  </Box>
}