import { CircularProgress } from "@mui/material"
import classNames from "classnames"
import { FC, ReactNode, useCallback, useState } from "react"
import { useDropzone } from 'react-dropzone'
import { useTranslation } from "react-i18next"
import { handleFilesUpload } from "../../../../_helpers/image.helpers"

type ImageUploaderUIProps = {
  label?: string
  multiple?: boolean
  children?: ReactNode
  onDrop?: (files: File[], preview: string[]) => void
  onError?: () => void
}
export const ImageUploaderUI: FC<ImageUploaderUIProps> = (props) => {
  const { t } = useTranslation("common")
  const [loading, setLoading] = useState(false);

  const {
    label = t('form.imageInput.label'),
    multiple = false,
    children,
    onDrop,
    onError
  } = props

  const onDropInternal = useCallback(async (acceptedFiles: File[]) => {
    setLoading(true);
    const { compressedFiles, base64Files } = await handleFilesUpload(acceptedFiles)
    onDrop && onDrop(compressedFiles, base64Files)
    onError && onError()
    setLoading(false);
  }, [onDrop])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onDropInternal, accept: 'image/jpg, image/jpeg, image/png', multiple })



  return <div>
    <div {...getRootProps({
      className: classNames(
        'p-3 hover:border-fcosecondaryhover border-dashed border-gray-300 border-2 flex flex-col w-full items-center gap-y-2 justify-center', {
        " border-fcosecondaryhover": isDragActive
      })
    })}
    >
      <input
        {...getInputProps()} />
      <p className="text-fcomain text-opacity-40">{label}</p>

      {loading &&
        <CircularProgress size={20} color="secondary" />
      }
      {!loading && children}

    </div>


  </div >
}