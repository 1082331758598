import { AttachFile, Send } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, Stack, TextField, Typography } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { ImageUploader } from "../images/ImageUploader.element";
import { useTranslation } from "react-i18next";

interface ChatContainerProps {
  title?: string;
  children?: React.ReactNode;
  attachments?: File[];
  disabled?: boolean;
  handleFiles?: (files: File[]) => void;
  loading: boolean;
  handleMessage?: (message: string) => void;
}
export const ChatContainer: FC<ChatContainerProps> = (props) => {
  const {
    title,
    children,
    loading = false,
    attachments = [],
    disabled = false,
    handleFiles = () => { },
    handleMessage = () => { },
  } = props;
  const { t } = useTranslation("ticket");
  const ImgUploaded = useRef<any>(null)
  const [message, setMessage] = useState("");
  // const [attachments, setAttachments] = useState<File[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [children]);
  return (
    <Box className="flex-col flex w-full flex-grow">
      {title && <Typography variant={"h6"}>{title}</Typography>}
      <Box
        sx={{
          overflowY: "scroll",
          maxHeight: "350px",
        }}
        className="flex-grow p-2 bg-white"
      >
        <Stack spacing={2}>{children}</Stack>
        <div ref={messagesEndRef} />
      </Box>
      <Box className="w-full">
        {ImgUploaded.current && attachments.length > 0 && <>{t("form.attachment") + " : " + attachments.map((file: File, i) => file.name)}<IconButton onClick={ImgUploaded.current.reset}><CloseIcon fontSize="small" /></IconButton></>}
      </Box>
      <Box className="flex gap-2 pt-2">
        <ImageUploader
          multiple={true}
          thumb={false}
          ref={ImgUploaded}
          onChange={handleFiles}
          currentImg={undefined}
          deleteButton={false}
          addBtn={<AttachFile />}
          name="attachments"
          form={null}
          simpleButton={true}
          preview={false}
        />
        <TextField
          disabled={disabled}
          size="small"
          multiline
          maxRows={3}
          placeholder={t('form.leaveComment')}
          value={message}
          onChange={(
            e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            setMessage(e.target.value);
          }}
          className="flex-auto"
        />
        <Box className="flex flex-col items-center ">
          {(loading) ? (
            <CircularProgress size={20} />
          ) : (
            <IconButton
              onClick={() => {
                handleMessage(message.trim());
                setMessage("");
              }}
              className="shadow-md flex-auto"
              color="secondary"
            >
              <Send />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};