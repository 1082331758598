import { Close } from "@mui/icons-material"
import classNames from "classnames"
import { FC, MouseEvent } from "react"

type ImagePreviewProps = {
  image: string
  allowDelete?: boolean
  onDelete?: () => void
  onClick?: (e: MouseEvent) => void,
  className?: string
  size?: "small" | "medium" | "large" | string
}
export const ImagePreview: FC<ImagePreviewProps> = ({
  image,
  className,
  size = 'medium',
  allowDelete = false,
  onClick = () => { },
  onDelete = () => { }
}) => {

  return <button
    className={classNames(className, "relative w-auto rounded overflow-hidden")}
    onClick={onClick}>
    <img alt="" className={classNames({
      "max-h-20": size === "small",
      "max-h-24": size === "medium",
      "max-h-92": size === "large"
    })} src={image} />
    {
      allowDelete && <button
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          onDelete()
        }}
        className='aspect-square pl-1 w-6 h-6 flex justify-center items-center absolute top-0 right-0 bg-fcodanger rounded-bl-full'>
        <Close sx={{
          fontSize: "12px"
        }} className='text-white' />
      </button>
    }

  </button>
}