import FDK from "@wattsonelements/front-fdk";
import { IAttachment } from "@wattsonelements/front-fdk/dist/models/Attachment.models";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { postDocument } from "../../../../../core/store/actions/Attachments.actions";
import { createTicketMessage, updateTicket } from "../../../../../core/store/actions/Ticket.actions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../core/store/hooks";
import { resetAttachment } from "../../../../../core/store/reducers/Attachments.reducer";
import { ChatContainer } from "../../../UX/chat/Chat";
import { ChatMessage } from "../../../UX/chat/Message";

interface TicketchatProps {
  [key: string]: any;
}
export const Ticketchat: FC<TicketchatProps> = (props) => {
  const { t } = useTranslation("ticket");
  const dispatch = useAppDispatch();
  const ticket = useAppSelector(({ tickets }) => tickets.selected);
  const user = useAppSelector(({ users }) => users.profile);
  const usersList = useAppSelector(({ users }) => users.list);
  const [nbAttachments, setNbAttachments] = useState<number>(0);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localMessage, setLocalMessage] = useState("");
  const uploaded = useAppSelector((state) => state.attachments.uploaded);
  const isAttachmentsDone = useAppSelector(
    (state) => state.attachments.uploaded.length === nbAttachments
  );

  const filesChanged = (files: File[]) => {
    setAttachments(files);
    setNbAttachments(files.length)
  }
  const attachmentsCB = () => {
    if (ticket) {
      setNbAttachments(0);
      // dispatch(addAttachment(uploaded))
      // dispatch(resetAttachment());
    }
  };

  useEffect(() => {
    if (submitting && attachments.length > 0) {
      for (let i in attachments) {
        dispatch(postDocument(attachments[i]));
      }
      setAttachments([])
    } else if (submitting && isAttachmentsDone) {
      if (uploaded.length > 0)
        attachmentsCB()
      setSubmitting(false);
      setLoading(true)
      sendMessage();
    }
  }, [submitting, isAttachmentsDone, attachments]);

  const handleMessage = (msg: string) => {
    setSubmitting(true);
    setLocalMessage(msg);
  }

  const sendMessage = async () => {
    if (ticket) {
      setLoading(true);

      const message = {
        message_from: user?.id,
        message_to: user?.id,
        // files: uploaded.map((file: IAttachment) => file.id!),
      } as any;
      if (uploaded.length > 0) {
        const attachments = [...(ticket.attachments as IAttachment[]).map(f => f.id!), ...uploaded.map(file => file.id!)]
        await dispatch(updateTicket({
          field: {
            attachments
          }, id: ticket.id
        }))
      }

      if (localMessage !== "") {
        message.ticket_message = localMessage
        dispatch(createTicketMessage({ message, id: ticket.id })).finally(() => setLoading(false));
      } else {
        setLoading(false)
      }

      dispatch(resetAttachment());
      setLocalMessage("");
    }
  }

  return (
    <>
      <ChatContainer
        loading={submitting || loading}
        {...props}
        title={t("comments")}
        attachments={attachments}
        handleFiles={filesChanged}
        handleMessage={handleMessage}
        disabled={ticket?.status === FDK.Constants.TicketConstants.TicketStatus.CLOSED ||
          ticket?.status === FDK.Constants.TicketConstants.TicketStatus.ARCHIVED}
      >
        {ticket?.messages?.map((message, i) => {
          const isUserMesage = message.message_from === user?.id;
          const messageUser = usersList.find(
            (u) => u.id === message.message_from
          );

          const dateFormat = () => {
            const date = message.date_created;
            if (i !== 0) {
              const previous = ticket?.messages![i - 1];
              if (dayjs(date).isSame(dayjs(previous.date_created), "day")) {
                return dayjs(date).format(t("dates.hour", { ns: "common" }));
              } else {
                return dayjs(date).format(
                  t("dates.date_hour", { ns: "common" })
                );
              }
            }
            return dayjs(date).format(t("dates.date_hour", { ns: "common" }));
          };

          return (
            <ChatMessage
              key={message.id}
              date={message.date_created}
              dateFormat={dateFormat}
              isUserMesage={isUserMesage}
              isSystem={message.system_message}
              message={message.ticket_message}
              files={message.files}
              user={message.user}
              avatar={isUserMesage ? user?.picture : messageUser?.picture}
            />
          );
        })}
      </ChatContainer>
    </>
  );
};
