import { useFormContext, Controller } from "react-hook-form"
import { TicketAssignees } from "../TicketAssignees"
import { Box } from "@mui/material"
import { useTranslation } from "react-i18next";
import { FC } from "react";

type AssigneesSelectProps = {
  required?: boolean
}
export const AssigneesSelect: FC<AssigneesSelectProps> = ({ required = false }) => {
  const { t } = useTranslation("ticket");

  const form = useFormContext()
  return <div className="flex justify-center items-center gap-2">
    <p className="text-sm m-0 p-0 block font-bold">
      {t("form.ref")}{required && "*"}
    </p>
    <Box className="flex w-full justify-end">
      <Controller
        name="assignees"
        control={form.control}
        rules={{
          required,
          validate: (value) => {
            if (!value || value.length === 0) return false
            return true
          }
        }}
        render={({ field }) => <TicketAssignees
          displayAllAssignees={true}
          onChange={(users) => {
            field.onBlur()
            field.onChange(users.map(as => as.id))
          }}
          canEdit={true}
          ticket={null}
        />}
      />

    </Box>
  </div>
}