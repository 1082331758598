import { Box, Skeleton } from "@mui/material";
import { FC } from "react";

export const TicketDetailLoading: FC = () => {
  return (
    <Box className="grid grid-cols-2 mt-2">
      <Box className="p-5">
        <Box className="flex items-center">
          <Skeleton variant="circular" width={40} height={40} />
          <Box className="ml-3">
            <Skeleton width={150} variant="text" />
            {/* <Skeleton width={100} variant="text" /> */}
          </Box>
        </Box>
        <Skeleton width={"100%"} height={30} variant="text" />
        <Skeleton width={40} variant="text" />
        <Skeleton width={"100%"} height={50} />
        <Skeleton width={"100%"} height={50} />
      </Box>
      <Box className="p-5">
        <Skeleton width={150} height={30} variant="text" />

        <Box className="flex items-center">
          <Skeleton
            sx={{ minWidth: 40 }}
            variant="circular"
            width={40}
            height={40}
          />
          <Box className="ml-3 w-full">
            <Skeleton width={"100%"} variant="text" />
          </Box>
        </Box>
        <Box className="flex items-center">
          <Box className="mr-3 w-full">
            <Skeleton width={"100%"} variant="text" />
          </Box>
          <Skeleton
            sx={{ minWidth: 40 }}
            variant="circular"
            width={40}
            height={40}
          />
        </Box>
        <Box className="flex items-center">
          <Skeleton
            sx={{ minWidth: 40 }}
            variant="circular"
            width={40}
            height={40}
          />
          <Box className="ml-3 w-full">
            <Skeleton width={"100%"} variant="text" />
          </Box>
        </Box>
        <Skeleton width={"100%"} height={50} />
      </Box>
    </Box>
  );
};
