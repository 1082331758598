import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import { FC } from 'react';
import classNames from 'classnames';

type ImageGalleryProps = {
  images: string[],
  className?: string
}
export const ImageGallery: FC<ImageGalleryProps> = ({ images, className }) => {
  return <div className="">
    <LightGallery
      licenseKey="AB6A2D4D-69FC40DA-B50E6E8B-F200CE4E"
      speed={500}
      elementClassNames={classNames(className, "flex gap-1 overflow-auto ")}
      plugins={[]}
    >
      {
        images.map((img, i) => <img key={img} data-src={img} className='block h-16 w-auto min-w-fit overflow-hidden rounded' alt={`gallery-${i}`} src={img} />)
      }
    </LightGallery>
  </div>
}