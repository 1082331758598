import { Box, Button, CircularProgress, IconButton, } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useTranslation } from "react-i18next";
import { useDropzone } from 'react-dropzone'
import { UseFormReturn } from 'react-hook-form';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { handleFilesUpload } from '../../../../_helpers/image.helpers';
import { type Crop } from 'react-image-crop'
import classNames from 'classnames';
import { CropImages } from './Image.crop';
import { ImagePreview } from './Image.preview';

type ImageUploaderProps = {
  thumb: boolean;
  form?: UseFormReturn | any;
  name: string;
  simpleButton?: boolean;
  multiple?: boolean;
  label?: string | null;
  displayLabel?: boolean;
  addBtn?: string | JSX.Element | null;
  delBtnText?: string | null;
  deleteButton?: boolean;
  currentImg?: string | undefined | null;
  images?: File[],
  onChange?: (files: File[]) => void;
  preview: boolean;
  hasCrop?: boolean;
  cropOptions?: {
    crop: Crop
    aspect?: number | null | undefined
  }
}
export const ImageUploader = forwardRef((props: ImageUploaderProps, ref: any) => {
  const {
    thumb,
    simpleButton = false,
    form = null,
    name,
    images = [],
    multiple = false,
    displayLabel = true,
    label = null,
    addBtn = null,
    delBtnText = null,
    deleteButton = false,
    currentImg,
    onChange = null,
    preview,
    hasCrop = false,
    cropOptions = {
      crop: {
        unit: 'px',
        height: 1080 / 2,
        width: 1920 / 2,
        x: 0,
        y: 0
      },
      aspect: 16 / 9
    }
  } = props;
  const { t } = useTranslation("common")
  const [loading, setLoading] = useState(false);
  const [localImgs, setLocalImgs] = useState((currentImg && currentImg !== "") ? [currentImg] : []);
  const [imgForCrop, setImgForCrop] = useState(0)
  const [compressed, setCompressed] = useState<File[]>([])
  const notCompressed = useRef<File[]>([])
  const [cropDone, setCropDone] = useState(false)

  useImperativeHandle(ref, () => ({
    reset() {
      handleDelete(null);
      setCropDone(false)
      setCompressed([])
      notCompressed.current = []
      setLocalImgs([])
      setImgForCrop(0)
    },
  }))

  useEffect(() => {
    if (typeof currentImg === "string" && currentImg !== "")
      setLocalImgs([currentImg])
  }, [currentImg])

  const handleDelete = (e: any) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (typeof onChange === "function")
      onChange([]);
    setLocalImgs([]);
    if (hasCrop) {
      setCropDone(false)
      setCompressed([])
      notCompressed.current = []
      setLocalImgs([])
      setImgForCrop(0)
    }
    if (form)
      form.setValue(name, multiple ? [] : "");
    return false;
  }

  const handleFiles = (files: File[]) => {
    return handleFilesUpload(files, () => {
      if (form) {
        form.setError(name, { type: 'custom', message: t("form.imageInput.imageError2") });
      }
    })
  }

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setLoading(true);
    if (hasCrop) {
      setCropDone(false)
      setCompressed([])
      notCompressed.current = []
      setLocalImgs([])
      setImgForCrop(0)
    }

    notCompressed.current = acceptedFiles
    const { compressedFiles, base64Files } = await handleFiles(acceptedFiles)

    if (form) {

      const currentValue = form.getValues(name)
      form.setValue(name, multiple ? [...currentValue, ...compressedFiles] : compressedFiles[0]);
      console.log("form update", name, currentValue, multiple ? [...currentValue, ...compressedFiles] : compressedFiles[0]);

    }

    if (multiple) {
      setLocalImgs([...localImgs, ...base64Files]);
    } else {
      setLocalImgs(base64Files);
    }


    if (typeof onChange === "function") {
      onChange(multiple ? [...images, ...compressedFiles] : compressedFiles);
    }

    setCompressed(multiple ? [...images, ...compressedFiles] : compressedFiles)
    setLoading(false);
  }, [])

  const removeFromMultiple = (i: number) => {
    if (!deleteButton) return
    if (!multiple) return
    const tempPreview = [...localImgs]
    tempPreview.splice(i, 1)
    setLocalImgs(tempPreview)

    const tempFiles = [...compressed]
    tempFiles.splice(i, 1)
    setCompressed(tempFiles)

    if (typeof onChange === "function") onChange(tempFiles);

  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpg, image/jpeg, image/png', multiple })

  return (
    <Box >
      <Box
        {...getRootProps({
          className: simpleButton ?
            ""
            :
            'p-3 hover:border-fcosecondaryhover border-dashed border-gray-300 border-2 flex flex-col w-full items-center gap-y-2 justify-center'
            + (isDragActive && " border-fcosecondaryhover")
        })}>
        {!simpleButton && displayLabel &&
          <p className="text-fcomain text-opacity-40">{label ? label : multiple ? t('form.imageInput.labelMultiple') : t('form.imageInput.label')}</p>
        }
        <Box className="flex items-center justif-center gap-x-2">
          {loading ?
            <CircularProgress size={20} color="secondary" /> :
            addBtn && typeof addBtn !== "string" ?
              <IconButton className="shadow-md flex-auto">
                {addBtn}
              </IconButton>
              :
              <Button
                component="span"
                variant="contained">
                {addBtn ? addBtn : t('form.imageInput.addBtn')}
                <AddPhotoAlternateIcon />
              </Button>
          }
          {deleteButton && !multiple && localImgs.length > 0 && localImgs[0] !== "" &&
            <Button
              onClick={handleDelete}
              variant="outlined"
              color="error">
              {delBtnText ? delBtnText : t('form.imageInput.delBtn')}
            </Button>}
        </Box>

        {!simpleButton &&
          <Box className="flex gap-2 flex-wrap">
            {preview && localImgs && localImgs.length > 0 &&
              localImgs.map((img, i) => img !== "" && <div key={"img-" + i} className='relative'>
                <ImagePreview
                  size={classNames({
                    "small": hasCrop || thumb || localImgs.length > 1,
                    "medium": !hasCrop && !thumb && localImgs.length > 1,
                    "large": !(thumb || localImgs.length > 1) && !hasCrop
                  })}
                  image={img}
                  onClick={(e) => {
                    if (hasCrop) {
                      e.stopPropagation()
                      e.preventDefault()
                      setImgForCrop(i)
                    }
                  }}
                  allowDelete={multiple && deleteButton}
                  onDelete={() => {
                    removeFromMultiple(i)
                  }}
                />
              </div>)
            }
          </Box>}

        {form ?
          <input
            multiple={multiple}
            className="hidden"
            accept="image/*"
            {...form.register(name, {})}
            id={name} type="file" />
          :
          <input
            multiple={multiple}
            className="hidden"
            accept="image/*"
            id={name} type="file" />
        }
        <input
          {...getInputProps()} />
      </Box>
      {/*
      * CROP 
      */}
      {
        !cropDone && !simpleButton
        // && compressed && compressed.length > 0
        && hasCrop && preview && localImgs
        && localImgs.length > imgForCrop && localImgs[imgForCrop] &&
        <CropImages
          cropOptions={cropOptions}
          onCrop={async (file: File) => {
            const tmp = [...compressed]
            const tmpPreview = [...localImgs]
            const { compressedFiles, base64Files } = await handleFiles([file])
            tmp[imgForCrop] = compressedFiles[0]
            tmpPreview[imgForCrop] = base64Files[0]
            if (typeof onChange === "function") {
              onChange(tmp);
            }
            if (form) {
              form.setValue(name, multiple ? tmp : tmp[0]);
            }
            setCompressed(tmp)
            setLocalImgs(tmpPreview)
            setCropDone(true)
          }}
          imageToCrop={localImgs[imgForCrop]} />
      }
    </Box>
  )
})