import { Box, Button } from "@mui/material"
import { FC, useRef, useState } from "react"
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from "react-image-crop"
import { cropToBlob } from "../../../../_helpers/image.helpers"
import { useTranslation } from "react-i18next"

type CropImagesProps = {
  cropOptions?: {
    crop: Crop
    aspect?: number | null | undefined
  }
  imageToCrop: string,
  onCrop: (file: File, base64Image: string) => void | Promise<any>
}
export const CropImages: FC<CropImagesProps> = (props) => {
  const { t } = useTranslation("common")

  const {
    imageToCrop,
    onCrop,
    cropOptions = {
      crop: {
        unit: 'px',
        height: 1080 / 2,
        width: 1920 / 2,
        x: 0,
        y: 0
      },
      aspect: 16 / 9
    } } = props
  const imageCropRef = useRef<HTMLImageElement>()

  const [crop, setCrop] = useState<Crop>(cropOptions.crop)

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, cropOptions?.aspect || 16 / 9))
  }

  return <Box className="my-2">
    <Box className="flex justify-between items-center mb-1">
      <p className='font-bold'>{t("form.imageInput.cropAndResize")}</p>
      <Box>
        {/* <Button color='error'>
        Reset
      </Button> */}
        <Button variant='contained' color='secondary' onClick={() => {
          cropToBlob(crop, imageCropRef.current, onCrop)

        }}>
          {t('form.imageInput.saveCrop')}
        </Button>
      </Box>
    </Box>
    <Box className="bg-slate-400 text-center">
      <ReactCrop
        minHeight={200}
        minWidth={200}
        onChange={(crop, percentCrop) => {
          setCrop(crop)
        }}
        onComplete={(crop, percentCrop) => {
          setCrop(crop)
        }}
        aspect={cropOptions?.aspect || undefined}
        crop={crop}
        className='rounded '
      >
        <img ref={imageCropRef as any}
          className='rounded'
          onLoad={onImageLoad}
          src={imageToCrop}
          alt="news cover to crop" />
      </ReactCrop>

    </Box>
  </Box>
}

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width: 500,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}