import { LocalPrintshop } from "@mui/icons-material";
import { Box, Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ImageUploader } from "../../../../UX/images/ImageUploader.element";

type CGUFieldProps = {
  disabled?: boolean,
  cguAcceptedFieldName: string
  cguUploadFieldName: string
}
export const CGUField: FC<CGUFieldProps> = ({ disabled, cguAcceptedFieldName, cguUploadFieldName }) => {
  const { control, watch, formState: { isValid } } = useFormContext()
  const { t } = useTranslation("technicalArea")

  const hasCGUAccepted = watch(cguAcceptedFieldName, false)

  return <Box className="col-span-2 flex flex-col gap-2">
    <h3 className="font-bold text-lg">{t("form.CGU")}</h3>
    <Controller
      control={control}
      name={cguAcceptedFieldName}
      render={({ field, fieldState, formState }) => (
        <RadioGroup
          aria-labelledby="cgu-group-label"
          defaultValue={false}
          onChange={(e) => {
            console.log(e);
            const val = e.target.value === "true" ? true : false
            field.onChange(val)
          }}
          onBlur={field.onBlur}
          name={field.name}
          ref={field.ref}
        >
          <FormControlLabel value={false} control={<Radio />} label={t("form.cguToBoater")} />
          <FormControlLabel value={true} control={<Radio />} label={t("form.cguFromMarina")} />
        </RadioGroup>)
      } />
    {
      hasCGUAccepted &&
      <div className="print:hidden">
        {/* <h3 className="font-bold text-lg">{t("form.CGU")}</h3> */}
        <Controller
          control={control}
          name={cguUploadFieldName}
          render={({ field, fieldState, formState }) => (
            <ImageUploader
              label={t("form.imguploader")}
              onChange={(val) => {
                console.log("val", val);
                if (val.length > 0) {
                  field.onChange(val[0])
                } else {
                  field.onChange(null)
                }

              }} preview={true} deleteButton={true} name="cgu" thumb={true} />)}
        />

      </div>
    }

  </Box>
}