import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  TextField,
  InputAdornment
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useAppDispatch, useAppSelector } from "../../../../core/store/hooks";
import { AlertType, useAppContext } from "../../../contexts/AppContext";
import { updateUser } from "../../../../core/store/actions/User.actions";
import { ImageUploader } from "../../UX/images/ImageUploader.element";
import FDK from "@wattsonelements/front-fdk";

export const UserEdit: React.FC = ({ children }) => {
  const { t } = useTranslation("app");
  const dispatch = useAppDispatch();
  const { setAlertMessage } = useAppContext();

  const { authStatus } = useAppSelector((state) => state.auth);
  const user = useAppSelector((state) => state.users.profile);

  const [loading, setLoading] = useState(false);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currentPwdError, setCurrentPwdError] = useState("");

  // show passwords
  const clickShowCurrent = () => setShowCurrentPassword(!showCurrentPassword);
  const MouseDownShowCurrent = () => setShowCurrentPassword(!showCurrentPassword);
  const clickShowNew = () => setShowNewPassword(!showNewPassword);
  const MouseDownShowNew = () => setShowNewPassword(!showNewPassword);
  const clickShowConfirm = () => setShowConfirmPassword(!showConfirmPassword);
  const MouseDownShowConfirm = () => setShowConfirmPassword(!showConfirmPassword);

  // form hooks
  const pwdForm = useForm({ mode: "onBlur" });
  const imgForm = useForm({ mode: "onBlur" });

  const submitPwd = async (data: any, event: any) => {
    setLoading(true);
    setCurrentPwdError("");
    event.preventDefault();
    if (!pwdForm.formState.isValid) {
      setAlertMessage!({ type: AlertType.ERROR, message: t('updateProfileModal.passwordError') })
      setLoading(false);
      return;
    }
    else {
      try {
        // check the current password
        await FDK.Auth.sendEmailCredentials(user!.email, data.old_password);
        let fd = new FormData();
        fd.append("password", data.password)
        dispatch(updateUser(fd)).then(
          (e) => {
            if (e.type === "user/update/rejected")
              setAlertMessage!({ type: AlertType.ERROR, message: t('updateProfileModal.fail') })
            else {
              setAlertMessage!({ type: AlertType.SUCCESS, message: t('updateProfileModal.success') })
              pwdForm.reset()
            }
            setLoading(false)
          },
          err => {
            setAlertMessage!({ type: AlertType.ERROR, message: t('updateProfileModal.fail') })
            setLoading(false)
          }
        )
      } catch (e) {
        setCurrentPwdError(t('updateProfileModal.passwordError'));
        setLoading(false)
        return;
      }
    }
  };

  const submitFile = (data: any, event: any) => {
    event.preventDefault();
    console.log(data);
    if (!(data.picture instanceof File))
      return;
    setLoading(true);
    const fd = new FormData();
    fd.append('picture', data.picture, data.picture.name);
    dispatch(updateUser(fd)).then(
      (e) => {
        if (e.type === "user/update/rejected")
          setAlertMessage!({ type: AlertType.ERROR, message: t('updateProfileModal.avatarError') })
        else {
          setAlertMessage!({ type: AlertType.SUCCESS, message: t('updateProfileModal.avatarSuccess') })
          imgForm.reset()
        }
        setLoading(false);
      },
      err => {
        setLoading(false);
        setAlertMessage!({ type: AlertType.ERROR, message: t('updateProfileModal.avatarError') })
      }
    )
  };

  return (
    <Box className="flex gap-x-3 flex-col justify-center lg:flex-row">
      <Card className="flex-1" variant="outlined">
        <CardContent>
          <Typography
            component="h1"
            variant="h6">
            {t("updateProfileModal.title")}
          </Typography>
          <Box
            component="form"
            onSubmit={pwdForm.handleSubmit(submitPwd)}
            // noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              {...pwdForm.register("old_password", {
                validate: (val: string) => {
                  if (authStatus === "error") {
                    return t("updateProfileModal.passwordError");
                  }
                },
                required: true,
              })}
              onChange={() => setCurrentPwdError("")}
              helperText={(!!pwdForm.formState.errors.old_password && pwdForm.formState.errors.old_password.message) || currentPwdError}
              error={!!pwdForm.formState.errors.old_password || currentPwdError !== ""}
              label={t("updateProfileModal.password")}
              type={showCurrentPassword ? "text" : "password"}
              id="old_password"
              autoComplete="current-password"
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={clickShowCurrent}
                      onMouseDown={MouseDownShowCurrent}
                    >
                      {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              {...pwdForm.register("password", {
                required: true,
                minLength: {
                  value: 8,
                  message: t("updateProfileModal.newPasswordLength"),
                },
              })}
              helperText={!!pwdForm.formState.errors.password && pwdForm.formState.errors.password.message}
              error={!!pwdForm.formState.errors.password}
              label={t("updateProfileModal.newPassword")}
              type={showNewPassword ? "text" : "password"}
              id="password"
              // autoComplete="current-password"
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={clickShowNew}
                      onMouseDown={MouseDownShowNew}
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              {...pwdForm.register("confirm_password", {
                validate: (val: string) => {
                  if (pwdForm.watch("password") !== val) {
                    return t("updateProfileModal.samePasswordError");
                  }
                },
                required: true,
              })}
              helperText={!!pwdForm.formState.errors.confirm_password && pwdForm.formState.errors.confirm_password.message}
              error={!!pwdForm.formState.errors.confirm_password}
              label={t("updateProfileModal.confirm")}
              type={showConfirmPassword ? "text" : "password"}
              id="confirm_password"
              // autoComplete="current-password"
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={clickShowConfirm}
                      onMouseDown={MouseDownShowConfirm}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!pwdForm.formState.isValid}
            >
              {loading ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                <>{t("updateProfileModal.savebtn")}</>
              )}
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Card className="flex-1" variant="outlined">
        <CardContent>
          <Typography
            component="h1"
            variant="h6">
            {t("updateProfileModal.updateAvatar")}
          </Typography>
          <Box
            component="form"
            onSubmit={imgForm.handleSubmit(submitFile)}
            noValidate
            sx={{ mt: 1 }}
          >
            <ImageUploader
              thumb={true}
              currentImg={user?.picture}
              deleteButton={false}
              addBtn={t("updateProfileModal.fileBtn")}
              name="picture"
              preview={true}
              form={imgForm} />
            <Button
              color="secondary"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            // disabled={!imgForm.formState.isValid}
            >
              {loading ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                <>
                  {t("updateProfileModal.savebtn")}
                </>
              )}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
